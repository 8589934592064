/* You can add global styles to this file, and also import other style files */
:root {
    --primary-color: #FECB00;
    --background-color: #363636;
    --text-color: #FECB00;

    --primary-btn-background-color: #FECB00;
    --primary-btn-text-color: #000000;
    --primary-btn-hover-background-color: #FECB00;
    --primary-btn-hover-text-color: #000000;

    --secondary-btn-background-color: #ffffff;
    --secondary-btn-text-color: #000000;
    --secondary-btn-hover-background-color: #e6e6e6;
    --secondary-btn-hover-text-color: #000000;

    --login-btn-background-color: #363636;
    --login-btn-text-color: #FECB00;
    --login-btn-hover-background-color: #363636;
    --login-btn-hover-text-color: #FECB00;

    --white-label-partner-primary-color: #fecb00;
    --white-label-partner-background-color: #363636;
    --white-label-partner-text-color: #fecb00;

    --white-label-partner-primary-btn-background-color: #fecb00;
    --white-label-partner-primary-btn-text-color: #000000;
    --white-label-partner-primary-btn-hover-background-color: #fecb00;
    --white-label-partner-primary-btn-hover-text-color: #000000;

    --white-label-partner-secondary-btn-background-color: #ffffff;
    --white-label-partner-secondary-btn-text-color: #000000;
    --white-label-partner-secondary-btn-hover-background-color: #e6e6e6;
    --white-label-partner-secondary-btn-hover-text-color: #000000;

    --white-label-partner-login-btn-background-color: #363636;
    --white-label-partner-login-btn-text-color: #fecb00;
    --white-label-partner-login-btn-hover-background-color: #363636;
    --white-label-partner-login-btn-hover-text-color: #fecb00;
  }

  .btn-primary {
    color: var(--primary-btn-text-color) !important;
    background-color: var(--primary-btn-background-color) !important;
    border-color: var(--primary-btn-background-color) !important;
  }

  .btn-primary:focus,
  .btn-primary:hover,
  .btn-primary:active {
    color: var(--primary-btn-hover-text-color) !important;
    background-color: var(--primary-btn-hover-background-color) !important;
    border-color: var(--primary-btn-hover-background-color) !important;
  }

  .disabled {
    cursor: not-allowed;
  }

  .btn-default {
    cursor: pointer;
    font-size: 14px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 40px !important;
    padding: 0 16px !important;
    border-radius: 4px !important;
    overflow: visible;
    transform: translate3d(0, 0, 0);
    transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    color: var(--secondary-btn-text-color) !important;
    background-color: var(--secondary-btn-background-color) !important;
    background-image: none !important;
    border: none !important;
  }

  .btn-default:hover {
    color: var(--secondary-btn-hover-text-color) !important;
    background-color: var(--secondary-btn-hover-background-color) !important;
  }

  .btn-primary, .md-drppicker .btn {
    cursor: pointer;
    font-size: 14px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 40px !important;
    padding: 0 16px !important;
    border-radius: 4px !important;
    overflow: visible;
    transform: translate3d(0, 0, 0);
    transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    color: var(--primary-btn-text-color) !important;
    background-color: var(--primary-btn-background-color) !important;
    background-image: none !important;
    border: none !important;
  }

  .btn-success {
    cursor: pointer;
    font-size: 14px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 40px !important;
    padding: 0 16px !important;
    border-radius: 4px !important;
    overflow: visible;
    transform: translate3d(0, 0, 0);
    transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    color: #fff !important;
    background-color: #28a745 !important;
    background-image: none !important;
    border: none !important;
  }

  .btn-sm {
    min-width: 60px !important;
    line-height: 25px !important;
    padding: 0 10px !important;
  }

  .input-group-append .btn,
  .input-group-prepend .btn {
    z-index: 0 !important;
  }

  html,
  body {
    height: 100%;
  }

  body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }

  .mat-tab-label-active {
    background-color: #ffffff;
    opacity: 1 !important;
  }

  .mat-tab-body-content {
    background-color: #ffffff;
    padding: 0px;
  }

  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: var(--primary-color);
  }

  .editableTemplate img {
    width: 100% !important;
    height: auto !important;
  }

  .no-data-available {
    text-align: center;
  }

  .dataTables_empty {
    display: none;
  }

  table {
    width: 100% !important;
  }

  .disabled,
  button:disabled,
  input:disabled {
    cursor: not-allowed !important;
  }

  .page-link {
    color: inherit !important;
  }

  /*Social login buttons*/
.btn-social {
    position: relative;
    padding-left: 44px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .btn-social :first-child {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 32px;
    line-height: 34px;
    font-size: 1.6em;
    text-align: center;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
  }

  .btn-facebook {
    color: #fff !important;
    background-color: #3b5998;
    border-color: rgba(0, 0, 0, 0.2);
  }

  .btn-google-plus {
    color: #fff !important;
    background-color: #dd4b39;
    border-color: rgba(0, 0, 0, 0.2);
  }

  .btn-google-plus:hover {
    background-color: #dd4b39 !important;
    filter: brightness(85%);
  }

  .btn-linkedin {
    color: #fff !important;
    background-color: #007bb6;
    border-color: rgba(0, 0, 0, 0.2);
  }

  .btn-linkedin:hover {
    background-color: #007bb6 !important;
    filter: brightness(85%);
  }

  /* Social login buttons */

  /* login page */
  .ngx-show-hide-password .btn-primary {
    box-shadow: none !important;
    border-radius: 0px 4px 4px 0px !important;
  }
  .form-control {
    height: 40px !important;
  }
  /* login page */

  .required-icon {
    color: red;
  }

  /* --- toast css--- */
  .toast-tech-hide {
    display: none;
  }
  .toast-tech-link {
    font-weight: 600;
    color: #000 !important;
  }
  .toast-tech-details b {
    font-weight: 600;
    color: #000 !important;
  }
  /* --- toast css --- */
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.mat-drawer-side {
  border-right: none;
}
.mat-drawer-container {
  background-color: #f3f3f4 !important;
}

.paid-color {
  color: #82af6f !important;
}

.paid-border-color {
  border-color: #82af6f !important;
}

.past-due-color {
  color: #d15b47 !important;
}

.past-due-border-color {
  border-color: #d15b47 !important;
}

.suspended-color {
  color: #f56c63 !important;
}

.suspended-border-color {
  border-color: #f56c63 !important;
}

.trial-expires-color {
  color: #2283c5 !important;
}

.trial-expires-border-color {
  border-color: #2283c5 !important;
}

.processing-color {
  color: #544179 !important;
}

.processing-border-color {
  border-color: #544179 !important;
}

.trial-expired-color {
  color: #F89406 !important;
}

.un-billed-color {
  color: #23c6c8 !important;
}

.un-billed-border-color {
  border-color: #23c6c8 !important;
}

.trial-expired-border-color {
  border-color: #F89406 !important;
}

.canceled-color {
  color: #a0a0a0 !important;
}

.canceled-border-color {
  border-color: #a0a0a0 !important;
}

.trial-expires-next-week-box .mat-expansion-panel-header-title h5 {
  display: inline-block;
  font-size: 14px !important;
  margin: 0px;
  padding: 0;
  text-overflow: ellipsis;
  line-height: 1.5 !important;
  font-weight: 500 !important;
  color: #676a6c;
}

.trial-expires-next-week-box .mat-expansion-panel-header-title {
  display: block !important;
  line-height: 1 !important;
}

.trial-expires-next-week-box .badge {
  font-weight: 500 !important;
  font-size: 13px !important;
}

.trial-expires-next-week-box .mat-expansion-panel-body {
  padding: 0px !important;
}

.trial-expires-next-week-box .mat-expansion-panel-header {
  background: #fff !important;
}

.trial-expires-next-week-box div.ibox-content {
  padding: 10px;
}

.trial-expires-next-week-box table thead th{
  border-top: none !important;
  border-bottom: none !important;
}

.badge-disabled-mfa, .badge-rejected {
  background-color: red;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.badge-enabled-mfa, .badge-verified {
  background-color: green;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.badge-pending {
  background-color: #f8ac59;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.badge-not-an-external-accountant {
  background-color: #666;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.sort-icon {
  background-color: #fff;
    font-weight: 400;
    padding-right: 18px;
    position: relative;
}

.sort-icon:after {
  border-top-color: #666 !important;
  margin-top: -4px;
}

.sort-icon:before {
  border-top-color: #666 !important;
  margin-top: 1px;
}

.sorting-asc:before {
  opacity: 1 !important;
}

.sorting-desc:after {
  opacity: 1 !important;
}

.table-sorting:after {
  right: 0.5em;
  content: "↓";
  display: block;
  top: 30%;
  position: absolute;
  opacity: 0.5;
}

.sorting_disabled {
  cursor: default !important;
}

.sorting_disabled:before, .sorting_disabled:after {
  content: "" !important;
}

.table-sorting:before {
  right: 1em;
  content: "↑";
  display: block;
  top: 20%;
  position: absolute;
  opacity: 0.5;
}

/** plan name css **/
.dot-entrepreneur-plan {
  background-color: #0206f8;
}

.badge-entrepreneur {
  background-color: #0206f8;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.dot-professional-plan {
  background-color: #23c6c8;
}

.badge-professional {
  background-color: #23c6c8;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.dot-professional-w-ecommerce-plan {
  background-color: #fdcf18;
}

.badge-professional-w-ecommerce {
  background-color: #fdcf18;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.dot-bank-accounting-online-plan {
  background-color: #d1dade;
}

.badge-bank-accounting-online {
  background-color: #d1dade;
  color: #5e5e5e;
  font-size: 13px !important;
  font-weight: 400;
}

.dot-partner-edition-plan {
  background-color: #ed5565;
}

.badge-accounting, .badge-partner-edition {
  background-color: #ed5565;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.dot-start-up-plan {
  background-color: #f8ac59;
}

.badge-start-up {
  background-color: #f8ac59;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.dot-writeup-plan {
  background-color: #28a745;
}

.badge-writeup {
  background-color: #28a745;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.dot-business-plan {
  background-color: #9828a7;
}

.badge-business {
  background-color: #9828a7;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}
/** plan name css **/

.white-bg {
  background-color: #ffffff;
}

.page-heading {
  border-top: 0;
  padding: 10px;
}

.page-heading h2 {
  margin-bottom: 10px !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 1.1 !important;
}

.breadcrumb {
  padding: 0!important;
  margin-bottom: 0!important;
  background-color: #fff!important;
  border-radius: 4px!important;
}

.breadcrumb>li+li:before {
  padding: 0 5px;
  color: #ccc;
  content: "/\00a0";
}

.acs-view-custom {
  min-width: auto !important;
}

.acs-view-active {
  background: var(--primary-color) !important;
  color: var(--background-color) !important;
}

#searchclear {
  position: absolute;
  right: 10px;
  top: 30px;
  bottom: 0;
  height: 14px;
  margin: auto;
  font-size: 14px;
  cursor: pointer;
  color: #ccc;
  z-index: 100;
}

.search-clear-icon {
  position: absolute;
  right: 10px;
  top: 30px;
  bottom: 0;
  height: 14px;
  margin: auto;
  font-size: 14px;
  cursor: pointer;
  color: #ccc;
  z-index: 100;
}

.account-filter-group .mat-form-field-appearance-legacy .mat-form-field-infix,
.sync-engine-filter-group .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0px !important;
  margin: 0px !important;
  border: none !important;
}

.account-filter-group .mat-form-field-appearance-legacy .mat-form-field-label,
.sync-engine-filter-group .mat-form-field-appearance-legacy .mat-form-field-label {
  top: 14px !important;
}

.account-filter-group .mat-form-field-appearance-legacy .mat-form-field-label b,
.sync-engine-filter-group .mat-form-field-appearance-legacy .mat-form-field-label b {
  font-weight: 500;
}

.account-filter-group .mat-form-field-underline,
.sync-engine-filter-group .mat-form-field-underline {
  display: none;
}

.ngx-pagination {
  padding: 0px !important;
  margin: 0 !important;
}

/** payment status css **/
.dot {
  height: 10px;
  border-radius: 6px;
  width: 10px;
  margin-top: 0px;
  margin-right: 5px;
  display: inline-block !important;
  vertical-align: middle !important;
}

.badge {
  font-size: 13px !important;
  font-weight: 400;
}

.subscription-status {
  display: flex !important;
}

.label-yellow-date {
  margin-left: 10px;
  text-align: center;
  font-size: 12px;
  padding: 5px 5px 5px 5px;
  color: #963 !important;
  border-color: #fee188 !important;
  background-color: #fee188 !important;
  border-radius: 0 !important;
  text-shadow: none !important;
  position: relative;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.label-yellow-date:before {
  left: -16px;
  border-left-color: transparent !important;
  display: inline-block;
  content: "";
  position: absolute;
  top: 0px;
  border: 9px solid transparent;
  border-color: #fee188;
  border-width: 14px 8px;
}

.payment-status-suspended-border {
  border: 5px solid #f56c63;
}
.dot-suspended-payment-status {
  background-color: #f56c63 !important;
}
.payment-status-suspended {
  margin-right: 10px;
  position: relative;
  font-size: 12px;
  padding: 5px 5px 5px 5px;
  background-color: #f56c63 !important;
  border-radius: 0;
  text-shadow: none;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.payment-status-suspended:after {
  border: 9px solid transparent;
  border-width: 14px 8px;
  border-left-color: #f56c63;
  display: inline-block;
  content: "";
  position: absolute;
  right: -16px;
  top: 0px;
}

.payment-status-paused-border {
  border: 5px solid #FECD70;
}
.dot-paused-payment-status {
  background-color: #FECD70 !important;
}
.payment-status-paused {
  margin-right: 10px;
  position: relative;
  font-size: 12px;
  padding: 5px 5px 5px 5px;
  background-color: #FECD70 !important;
  border-radius: 0;
  text-shadow: none;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.payment-status-paused:after {
  border: 9px solid transparent;
  border-width: 14px 8px;
  border-left-color: #FECD70;
  display: inline-block;
  content: "";
  position: absolute;
  right: -16px;
  top: 0px;
}

.payment-status-cancelled-border {
  border: 5px solid #a0a0a0;
}
.dot-cancelled-payment-status {
  background-color: #a0a0a0 !important;
}
.payment-status-cancelled {
  margin-right: 10px;
  position: relative;
  font-size: 12px;
  padding: 5px 5px 5px 5px;
  background-color: #a0a0a0 !important;
  border-radius: 0;
  text-shadow: none;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.payment-status-cancelled:after {
  border: 9px solid transparent;
  border-width: 14px 8px;
  border-left-color: #a0a0a0;
  display: inline-block;
  content: "";
  position: absolute;
  right: -16px;
  top: 0px;
}

.payment-status-past-due-border {
  border: 5px solid #d15b47;
}
.dot-past-due-payment-status {
  background-color: #d15b47 !important;
}
.payment-status-past-due {
  margin-right: 10px;
  position: relative;
  font-size: 12px;
  padding: 5px 5px 5px 5px;
  background-color: #d15b47 !important;
  border-radius: 0;
  text-shadow: none;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.payment-status-past-due:after {
  border: 9px solid transparent;
  border-width: 14px 8px;
  border-left-color: #d15b47;
  display: inline-block;
  content: "";
  position: absolute;
  right: -16px;
  top: 0px;
}

.payment-status-paid-border, .payment-status-unbilled-border, .payment-status-partner-border {
  border: 5px solid #82af6f;
}
.dot-paid-payment-status, .dot-partner-payment-status, .dot-unbilled-payment-status {
  background-color: #82af6f !important;
}
.payment-status-paid,
.payment-status-unbilled,
.payment-status-partner {
  margin-right: 10px;
  position: relative;
  font-size: 12px;
  padding: 5px 5px 5px 5px;
  background-color: #82af6f !important;
  border-radius: 0;
  text-shadow: none;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.payment-status-paid:after,
.payment-status-unbilled:after,
.payment-status-partner:after {
  border: 9px solid transparent;
  border-width: 14px 8px;
  border-left-color: #82af6f;
  display: inline-block;
  content: "";
  position: absolute;
  right: -16px;
  top: 0px;
}

.payment-status-trial-expired-border {
  border: 5px solid #f89406;
}
.dot-trial-expired-payment-status {
  background-color: #f89406 !important;
}
.payment-status-trial-expired {
  position: relative;
  font-size: 12px;
  padding: 5px 5px 5px 5px;
  background-color: #f89406 !important;
  border-radius: 0;
  text-shadow: none;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.payment-status-processing-border {
  border: 5px solid #544179;
}
.dot-processing-payment-status {
  background-color: #544179 !important;
}
.payment-status-processing {
  position: relative;
  font-size: 12px;
  padding: 5px 5px 5px 5px;
  background-color: #544179 !important;
  border-radius: 0;
  text-shadow: none;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.payment-status-trial-expires-border {
  border: 5px solid #2283c5;
}
.dot-trial-expires-payment-status {
  background-color: #2283c5 !important;
}
.payment-status-trial-expires {
  margin-right: 10px;
  position: relative;
  font-size: 12px;
  padding: 5px 5px 5px 5px;
  background-color: #2283c5 !important;
  border-radius: 0;
  text-shadow: none;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.payment-status-trial-expires:after {
  border: 9px solid transparent;
  border-width: 14px 8px;
  border-left-color: #2283c5;
  display: inline-block;
  content: "";
  position: absolute;
  right: -16px;
  top: 0px;
}
/** payment status css **/

.not-available {
  color: #6e656566;
  font-style: italic;
}

.table.dataTable > thead > tr > th {
  vertical-align: top !important;
}

/* grid view css */
.grid-view .ibox-title {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  background-color: #ffffff;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 2px 0 0;
  color: inherit;
  margin-bottom: 0;
  padding: 10px !important;
  display: block !important;
}

.grid-view .ibox-title h5 {
  line-height: 1.4 !important;
  width: 65%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.grid-view .ibox-content {
  background-color: #ffffff;
  color: inherit;
  padding: 15px 10px 20px 10px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 0;
  min-height: 200px !important;
}

/** account status css **/
.dot-test-status {
  background-color: blue;
}

.acs-test-status {
  height: 10px;
  border-radius: 6px;
  width: 10px;
  background-color: blue;
  margin-top: 10px;
  margin-right: 5px;
  display: inline-block !important;
  vertical-align: middle !important;
}

.dot-live-status {
  background-color: green;
}

.acs-live-status {
  height: 10px;
  border-radius: 6px;
  width: 10px;
  background-color: green;
  margin-top: 10px;
  margin-right: 5px;
  display: inline-block !important;
  vertical-align: middle !important;
}

.dot-sandbox-status {
  background-color: orange;
}

.acs-sandbox-status {
  height: 10px;
  border-radius: 6px;
  width: 10px;
  background-color: orange;
  margin-top: 10px;
  margin-right: 5px;
  display: inline-block !important;
  vertical-align: middle !important;
}

.dot-delete-status {
  background-color: #ff1323;
}

.acs-delete-status {
  height: 10px;
  border-radius: 6px;
  width: 10px;
  background-color: #ff1323;
  margin-top: 10px;
  margin-right: 5px;
  display: inline-block !important;
  vertical-align: middle !important;
}
/** account status css **/

.item-per-page-option > .mat-mdc-select {
  width: 15% !important;
}

.list-view-status {
  margin-top: -4px !important;
}

.required {
  color: red !important;
}

.invalid-feedback {
  display: block !important;
}

.mat-calendar-body-selected {
  background-color: var(--primary-color) !important;
}

.max-with-btn {
  max-width: 150px !important;
}

.account-details-container .mat-expansion-panel-body {
  padding: 0 !important;
}

.account-details-container .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.account-details-container .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: white;
}

#vertical-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 28px;
  height: 100%;
  width: 4px;
  background: #f1f1f1;
}

#vertical-timeline {
  position: relative;
  padding: 0;
  margin-top: 2em;
  margin-bottom: 2em;
}

.vertical-timeline-content {
  position: relative;
  margin-left: 60px;
  background: white;
  border-radius: 0.25em;
  padding: 1em;
}

.vertical-timeline-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 16px;
  border: 3px solid #f1f1f1;
  text-align: center;
  background-color: #f3f3f4;
}

.vertical-timeline-block:first-child {
  margin-top: 0;
}

.vertical-timeline-block {
  position: relative;
  margin: 2em 0;
}

.vertical-timeline-icon i {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -9px;
}

.vertical-container {
  padding: 0px 10px !important;
}

.dark-timeline .vertical-timeline-content, .dark-timeline.center-orientation .vertical-timeline-content {
  background: #f5f5f5;
}

.dark-timeline .vertical-timeline-content:before {
  border-color: transparent #f5f5f5 transparent transparent;
}

.vertical-timeline-content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid white;
}

.vertical-timeline-content:after {
  content: "";
  display: table;
  clear: both;
}

.dataTables_wrapper .form-control{
  height: auto !important;
}

.page-item.active .page-link {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.transactions-count .mat-form-field-infix {
  border-top: none !important;
}

.transactions-count .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0px !important;
}

.transactions-count .mat-form-field-underline {
  display: none;
}

.user-image {
  border-radius: 50%;
  width: 100%;
}


.dot-partner---reseller {
  height: 10px;
  border-radius: 6px;
  width: 10px;
  background-color: #a76118;
  margin-top: 0px;
  margin-right: 5px;
  display: inline-block !important;
  vertical-align: middle !important;
}

.dot-partners---fan-club {
  height: 10px;
  border-radius: 6px;
  width: 10px;
  background-color: #4aa786;
  margin-top: 0px;
  margin-right: 5px;
  display: inline-block !important;
  vertical-align: middle !important;
}

.dot-partners---verified-2024 {
  height: 10px;
  border-radius: 6px;
  width: 10px;
  background-color: #4a72a7;
  margin-top: 0px;
  margin-right: 5px;
  display: inline-block !important;
  vertical-align: middle !important;
}

.dot-customers---early-adopters {
  height: 10px;
  border-radius: 6px;
  width: 10px;
  background-color: #a78359;
  margin-top: 0px;
  margin-right: 5px;
  display: inline-block !important;
  vertical-align: middle !important;
}

.dot-partners---verified {
  height: 10px;
  border-radius: 6px;
  width: 10px;
  background-color: #8178a7;
  margin-top: 0px;
  margin-right: 5px;
  display: inline-block !important;
  vertical-align: middle !important;
}

.dot-customers---default-2024 {
  height: 10px;
  border-radius: 6px;
  width: 10px;
  background-color: #f1c48c;
  margin-top: 0px;
  margin-right: 5px;
  display: inline-block !important;
  vertical-align: middle !important;
}

.dot-customers---default {
  height: 10px;
  border-radius: 6px;
  width: 10px;
  background-color: #a79f95;
  margin-top: 0px;
  margin-right: 5px;
  display: inline-block !important;
  vertical-align: middle !important;
}

.badge-customers---default {
  background-color: #a79f95;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.badge-customers---default-2024 {
  background-color: #f1c48c;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.badge-partners---verified {
  background-color: #8178a7;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.badge-customers---early-adopters {
  background-color: #a78359;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.badge-partners---verified-2024 {
  background-color: #4a72a7;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.badge-partners---fan-club {
  background-color: #4aa786;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.badge-partner---reseller {
  background-color: #a76118;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

table.dataTable > thead > tr > th:not(.sorting_disabled), table.dataTable > thead > tr > td:not(.sorting_disabled) {
  padding-right: 30px !important;
  cursor: pointer !important;
}

.user-list-box {
  width: 100% !important;
}

.status-title {
  display: flex !important;
}

.user-profile-icon {
  margin-right: 6px;
  color: var(--primary-color);
  font-size: 17px;
  font-weight: 600;
}

.user-grid-item:not(:last-child) {
  border-bottom: 1px solid #c2c6ca;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.grid-user-type {
  position: absolute;
  right: 5px;
  top: -15px;
  z-index: 1;
  overflow: hidden;
  width: 132px;
  height: 134px;
  text-align: right;
}

.grid-user-type span {
  font-size: 10px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  line-height: 20px;
  transform: rotate(
45deg
);
  width: 179px;
  display: block;
  background: #a79f95;
  background: linear-gradient(#c3c1c9 0%, #a7a6a4 100%);
  box-shadow: 0 3px 10px -5px rgb(0 0 0);
  position: relative;
  top: 47px;
  right: 13px;
}

.grid-user-type span.customers---default {
  background: #a79f95;
  background: linear-gradient(#c3c1c9 0%, #a7a6a4 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
}

.grid-user-type span.customers---default-2024 {
  background: #f1c48c;
  background: linear-gradient(#f1c48c 0%, #ad976a 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
}

.grid-user-type span.partners---verified {
  background: #8178a7;
  background: linear-gradient(#8d8ac9 0%, #6e6ea7 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
}

.grid-user-type span.customers---early-adopters {
  background: #a78359;
  background: linear-gradient(#c9938a 0%, #a78578 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
}

.grid-user-type span.partners---fan-club {
  background: #4aa786;
  background: linear-gradient(#64c9b9 0%, #4ca7a3 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
}

.grid-user-type span.partners---verified-2024 {
  background: #4a72a7;
  background: linear-gradient(#647fc9 0%, #4c69a7 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
}

.grid-user-type span.partner---reseller {
  background: #a76118;
  background: linear-gradient(#c97c1f 0%, #a76628 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
}

.grid-user-type span.legacy {
  background: #fdcf18;
  background: linear-gradient(#fdcf18 0%, #fdcf18 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
}

.dot-partner-status-verified {
  height: 10px;
  border-radius: 6px;
  width: 10px;
  background-color: green;
  margin-top: 0px;
  margin-right: 5px;
  display: inline-block !important;
  vertical-align: middle !important;
}

.dot-partner-status-rejected {
  height: 10px;
  border-radius: 6px;
  width: 10px;
  background-color: red;
  margin-top: 0px;
  margin-right: 5px;
  display: inline-block !important;
  vertical-align: middle !important;
}

.dot-partner-status-pending {
  height: 10px;
  border-radius: 6px;
  width: 10px;
  background-color: #f8ac59;
  margin-top: 0px;
  margin-right: 5px;
  display: inline-block !important;
  vertical-align: middle !important;
}

.border-verified {
  border: 5px solid green;
}

.border-rejected {
  border: 5px solid red;
}

.border-pending {
  border: 5px solid #f8ac59;
}

.border-not-an-external-accountant {
  border: 5px solid #666;
}

.border- {
  border: 5px solid white;
}

.display-flex {
  display: flex !important;
}

.check-user-form {
  display: flex !important;
  justify-content: center;
}

.check-user-form .form-group {
  margin-bottom: 5px !important;
}

.check-user-form .form-control {
  min-width: 300px !important;
}

.profile-details-box .mat-expansion-panel-body {
  padding: 0px 0px !important;
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgb(255, 255, 255) !important;
}

.mat-expansion-panel-header-title h5 {
  margin-bottom: 0px !important;
}

.profile-image {
  width: 100px;
  border-radius: 50%;
  height: 100px;
  border: 4px solid #e4e4e4 !important;
}

.table thead th {
  vertical-align: top;
  cursor: pointer;
}

.badge-disabled-user-state {
  background-color: red;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.badge-enabled-user-state {
  background-color: green;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.profile-details-box .mat-expansion-panel-spacing {
  margin: 0px !important;
}

.badge-new_tenant_creation {
  background-color: #23c6c8;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.badge-pending_invitation {
  background-color: #fdcf18;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.subscription-status span {
  min-width: 80px !important;
}

.mat-expansion-panel-header {
  padding: 0 15px !important;
}

.mat-elevation-z0 {
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
}

/*  email log events */
.dot-processed-event, .badge-processed-event {
  background-color: #246201;
}

.dot-dropped-event, .badge-dropped-event {
  background-color: #d59f7f;
}

.dot-delivered-event, .badge-delivered-event {
  background-color: #bcd514;
}

.dot-deferred-event, .badge-deferred-event {
  background-color: #6b6b6b;
}

.dot-bounce-event, .badge-bounce-event {
  background-color: #c042be;
}

.dot-blocked-event, .badge-blocked-event {
  background-color: #aa0202;
}

.dot-open-event, .badge-open-event {
  background-color: #028690;
}

.dot-click-event, .badge-click-event {
  background-color: #59c1ca;
}

.dot-spam-report-event, .badge-spam-report-event {
  background-color: #e04427;
}

.dot-unsubscribe-event, .badge-unsubscribe-event {
  background-color: #3e44c0;
}

.dot-group-unsubscribe-event, .badge-group-unsubscribe-event {
  background-color: #fbe500;
}

.dot-group-resubscribe-event, .badge-group-resubscribe-event {
  background-color: #d59f7f;
}

.md-drppicker.double {
  min-width: 600px !important;
}

.ngx-pagination .disabled {
  padding: 3px 10px;
}


.md-drppicker .ranges ul li button.active  {
  background-color: var(--primary-color) !important;
}

.md-drppicker td.active, .md-drppicker td.active:hover {
  background-color: var(--primary-color) !important;
  border-color: transparent;
  color: #fff;
}

.md-drppicker th {
  color: #000000 !important;
  font-weight: 500 !important;
}


/* filter-by css */
.filtered-by-box .alert{
  padding: 0px 15px 0px 5px !important;
}

.filtered-by-box .alert-dismissible .close {
   padding: 0rem 0.25rem !important;
}

.filtered-by-box .close {
  font-size: 18px !important;
}

.filtered-by-box .alert-success {
  color: #333333;
  background-color: #fef2c7;
  border-color: rgba(0,0,0,0.4);
}

.filter-by-text {
  color: red;
  width: 70px !important;
  display: inline-block;
}

.filtered-by-box strong {
  font-family: 'Almarai', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10pt;
  color: #4b4b4b !important;
  opacity: .7;
}

.filtered-by-box {
  font-size: 14px;
  border-top: 1px solid #e7eaec;
  padding: 5px;
}
/* filter-by css */

.dot-debug-category, .badge-debug-category {
  background-color: #8a6d3b;
  color: #fcf8e3;
}

.dot-info-category, .badge-info-category {
  background-color: #31708f;
  color: #d9edf7;
}

.dot-error-category, .badge-error-category {
  background-color: #a94442;
  color: #f2dede;
}

.dot-debug-category, .badge-debug-category {
  background-color: #0c5460;
  color: #d1ecf1;
}

.dot-verbose-category, .badge-verbose-category {
  background-color: #d6d8d9;
  color: #1b1e21;
}

.dot-warning-category, .badge-warning-category {
  background-color: #8a6d3b;
  color: #fcf8e3;
}

.dot-admin-type, .badge-admin-type {
  background-color: #346DEF;
  color: #FFFFFF;
}

.dot-account-type, .badge-account-type {
  background-color: #95ef34;
  color: #000;
}

.dot-notification-type, .badge-notification-type {
  background-color: #ff1010;
  color: #000;
}

.dot-plan-type, .badge-plan-type {
  background-color: #d54663;
  color: #000;
}

.dot-dev-type, .badge-dev-type {
  background-color: #D6A45E;
  color: #000;
}

.dot-login-type, .badge-login-type {
  background-color: #BCD514;
  color: #000;
}

.dot-logout-type, .badge-logout-type {
  background-color: #459E1A;
  color: #000;
}

.dot-profile-type, .badge-profile-type {
  background-color: #B2129F;
  color: #000;
}

.dot-pay-type, .badge-pay-type {
  background-color: #683ad0;
  color: #000;
}

.note-modal-backdrop {
  position: initial !important;
}

.note-modal-footer {
  height: 60px !important;
}

.btn-export {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  color: var(--secondary-btn-text-color) !important;
  background-color: var(--secondary-btn-background-color) !important;
  border: 1px solid #e7eaec!important;
  font-size: 16px;
  line-height: 2.2 !important;
  padding: 0px 10px;
}

.iti {
  width: 100% !important;
}

intl-telephone-input {
  display: block !important;
  width: 100% !important;
}

ngx-mat-color-toggle  button{
  border: 1px solid black !important;
}

/** white label partner theme settings css **/
.config-btn-primary {
  color: var(--white-label-partner-primary-btn-text-color) !important;
  background-color: var(--white-label-partner-primary-btn-background-color) !important;
  border-color: var(--white-label-partner-primary-btn-background-color) !important;
}

.config-btn-primary:focus,
.config-btn-primary:hover,
.config-btn-primary:active {
  color: var(--white-label-partner-primary-btn-hover-text-color) !important;
  background-color: var(--white-label-partner-primary-btn-hover-background-color) !important;
  border-color: var(--white-label-partner-primary-btn-hover-background-color) !important;
}

.config-btn-default {
  cursor: pointer;
  font-size: 14px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 40px !important;
  padding: 0 16px !important;
  border-radius: 4px !important;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
    box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  color: var(--white-label-partner-secondary-btn-text-color) !important;
  background-color: var(--white-label-partner-secondary-btn-background-color) !important;
  background-image: none !important;
  border: none !important;
}

.config-btn-default:hover {
  color: var(--white-label-partner-secondary-btn-hover-text-color) !important;
  background-color: var(--white-label-partner-secondary-btn-hover-background-color) !important;
}

.config-btn-primary {
  cursor: pointer;
  font-size: 14px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 40px !important;
  padding: 0 16px !important;
  border-radius: 4px !important;
  overflow: visible;
  transform: translate3d(0, 0, 0);
  transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1),
    box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  color: var(--white-label-partner-primary-btn-text-color) !important;
  background-color: var(--white-label-partner-primary-btn-background-color) !important;
  background-image: none !important;
  border: none !important;
}

.config-btn-login {
  color: var(--white-label-partner-login-btn-text-color);
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--white-label-partner-login-btn-background-color);
  font-weight: 600;
}

.config-btn-login:hover {
  color: var(--white-label-partner-login-btn-hover-text-color);
  background-color: var(--white-label-partner-login-btn-hover-background-color);
}
/** white label partner theme settings css **/

.white-label-templates .mat-expansion-panel-body, .white-label-partner-container .mat-expansion-panel-body {
  padding: 0px !important;
}

.reseller-filters-container .mat-expansion-panel-body {
  padding: 5px !important;
  border-top: 1px solid #e7eaec;
}

.ngx-pagination li {
  margin-right: 0px !important;
}


.profile-edit-icon {
  position: absolute;
  top: 4px;
  font-size: 25px;
  right: 12px;
  color: #000;
  cursor: pointer;
  z-index: 10;
}

.change-pricing-level-icon {
  position: absolute;
  top: 4px;
  font-size: 25px;
  left: 12px;
  color: #000;
  cursor: pointer;
  z-index: 10;
}

.table-responsive>.table-bordered {
  border: 1px solid #dee2e6;
  border-right: 1px !important;
  border-top: 1px !important;
}

textarea.form-control {
  height: auto !important;
}

::placeholder {
  color: rgb(232,232,232) !important;
}

/*--sm logs--*/
.badge-error-type {
  background-color: red;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.badge-success-type {
  background-color: green;
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.dot-rest-service-type, .badge-rest-service-type {
  background-color: #B2129F;
  color: #000;
}

.dot-soap-service-type, .badge-soap-service-type {
  background-color: #683ad0;
  color: #000;
}

.ag-grid-cell-background-green {
  background-color: palegreen !important;
}

.ag-grid-cell-background-red {
  background-color: lightpink !important;
}

/*
Bootstrap v5 underline links fix
https://stackoverflow.com/questions/65480036/bootstrap-5-underline-default-changed
*/
.text-underline-hover {
  text-decoration: none;
}

.text-underline-hover:hover {
  text-decoration: underline;
}
/* bootstrap underline links fix end */

.cdk-overlay-pane:has(div.mat-select-autowidth-panel-class) {
  width: auto !important;
}

.filter-close-btn {
  padding: 0 !important;
}

ul.ngx-pagination > li > a {
  text-decoration: none !important;
}

td > a {
  text-decoration: none !important;
}

td > a:hover {
  text-decoration: none !important;
}

.mdc-tab--active {
  background: #ffffff !important;
}

.mat-mdc-tab {
  min-width: 200px !important;
}

.mat-calendar-table-header {
  font-size: 0.75rem !important;
}

.mdc-list-item--selected .mdc-list-item__primary-text, .mdc-list-item--activated .mdc-list-item__primary-text{
  color:#000 !important;
}

.intl-tel-input {
  border: 0;
}

.intl-tel-input:focus-visible {
  border: 0;
  outline-color: white;
}

intl-telephone-input > .input-container {
  border-radius: 5px;
}

.searchCountry__input {
  background-color: white !important;
}

.cloudPayrollEmployee .fa-check-circle {
  color: green;
  font-size: 24px;
}
.cloudPayrollEmployee .fa-times-circle-o {
  color: #ff0000;
  font-size: 24px;
}

.dot-startup-monthly-plan {
  background-color: rgb(248,172,89,0.8);
}

.badge-startup-monthly {
  background-color: rgb(248,172,89,0.8);
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.dot-startup-annual-plan {
  background-color: rgb(248,172,89,0.5);
}

.badge-startup-annual {
  background-color: rgb(248,172,89,0.5);
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.dot-business-monthly-plan {
  background-color: rgb(152,40,167, 0.8);
}

.badge-business-monthly {
  background-color: rgb(152,40,167, 0.8);
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.dot-business-annual-plan {
  background-color: rgb(152,40,167, 0.5);
}

.badge-business-annual {
  background-color: rgb(152,40,167, 0.5);
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.dot-professional-monthly-plan {
  background-color: rgb(35,198,200,0.8);
}

.badge-professional-monthly {
  background-color: rgb(35,198,200,0.8);
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.dot-professional-annual-plan {
  background-color: rgb(35,198,200,0.5);
}

.badge-professional-annual {
  background-color: rgb(35,198,200,0.5);
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.dot-professional-w-ecommerce-monthly-plan {
  background-color: rgb(253,207,24,0.8);
}

.badge-professional-w-ecommerce-monthly {
  background-color: rgb(253,207,24,0.8);
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.dot-professional-w-ecommerce-annual-plan {
  background-color: rgb(253,207,24,0.5);
}

.badge-professional-w-ecommerce-annual {
  background-color: rgb(253,207,24,0.5);
  color: #FFFFFF;
  font-size: 13px !important;
  font-weight: 400;
}

.iti.iti--allow-dropdown {
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: .375rem;
}


.review-header td,
.review-footer td,
.review-sub-footer td {
  vertical-align: top;
  padding: 10px;
}
.review-header div {
  margin: 0 auto;
  max-width: 600px;
}
.review-header div {
  color: #0068a5;
}
.review-header img {
  outline: none;
  text-decoration: none;
  -ms-interpolation-mode: bicubic;
  clear: both;
  display: block !important;
  border: 0;
  height: auto;
  float: none;
  width: 100%;
  max-width: 300px;
}
.review-body {
  background-color: #f0f0f0;
}
.review-body div {
  margin: 0 auto;
  max-width: 600px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.review-footer {
  background-color: #f7c907;
}
.review-footer div {
  margin: 0 auto;
  max-width: 600px;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
}
.review-sub-footer {
  background-color: #1e1b18;
}
.review-sub-footer div{
  margin: 0 auto;
  max-width: 600px;
}
.review-sub-footer p {
  color: #dedede;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1.3rem;
  font-weight: 500;
}
.review-social-icons{
    vertical-align: top; 
    padding: 20px 10px 10px 10px;
}
.reivew-footer-text{
    color: #dedede;
}
.review-copyright-text{
    color: #f7c907;
}